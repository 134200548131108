import * as React from "react";
import sendForm from "core/services/api/sendForm";
import {
    Form as FormModel,
    ContactTypeEntity,
    ContactType,
    FormOptions,
    FormFields,
} from "core/models";
import ProductEntity from "core/models/Entities/ProductEntity";
import { FormBackEndModel } from "core/models/FormBackEndModel";
import {
    FormResponse,
    FormFieldset,
    ModuleWrapper,
    FormSelect,
    Loader,
    Button,
    Container,
    Title,
} from "view/components";
import ConfigContext from "view/context/ConfigContext";
import fetchContactTypes from "core/services/api/contactTypes";
import fetchContactProducts from "core/services/api/contactProducts";
import sendContactAnnex from "core/services/api/contactAnnex";
import { customerFields } from "./FormFields";
import $ from "./Form.module.scss";
import { useLocation } from "react-router-dom";

type MessageType = "loading" | "success" | "fail";
type Props = {
    title: FormModel["title"];
    formPostSubscriptionKey?: FormModel["formPostSubscriptionKey"];
};

function mapFields(fields: FormFields[]) {
    return fields.map((field) => {
        return {
            name: field.name,
            value:
                (field.type === "select" || field.type === "radio") &&
                field.options
                    ? field.options[0].value
                    : "",
        };
    });
}

// @TODO: find correct types
function createInitialState(fieldset: any) {
    // Get all field ids and when a field is a select, use the first option as default value
    const nameValuePairFields = mapFields(fieldset.fields);
    const state: any = {};

    nameValuePairFields.forEach((obj: any) => {
        state[obj.name] = obj.value;
    });
    return state;
}

function convertProductListToOptions(
    productList: ProductEntity[],
    prop: string,
): FormOptions[] {
    // @TODO: find correct types
    return productList.map((product: any) => {
        return { value: product[prop as any], label: product[prop] };
    });
}

function removeDuplicatesFromNestedArr(arr: any[], prop: string) {
    return arr.filter(
        (item, index, self) =>
            index === self.findIndex((t) => t[prop] === item[prop]),
    );
}

async function send(
    apiUrl: string,
    subscriptionKey: string | undefined,
    form: any,
) {
    const body = form;
    const response = await sendForm(apiUrl, subscriptionKey, body);
    return response;
}

// @TODO: find correct types
async function sendUploadedFiles({
    apiUrl,
    subscriptionKey,
    files,
    contactFormId,
    setMessage,
}: any) {
    const filesArray: File[] = [];
    let i;

    for (i = 0; i < files.length; i += 1) {
        filesArray.push(files.item(i));
    }

    const response = await sendContactAnnex({
        apiUrl,
        subscriptionKey,
        body: filesArray,
        contactFormId,
    });

    if (response && response.status === 200) {
        setMessage("success");
        return response;
    }

    return setMessage("fail");
}

const Form = (props: Props) => {
    const { title, formPostSubscriptionKey } = props;
    const configContext = React.useContext(ConfigContext);

    const fieldsets = customerFields();
    const offerComplaintFields = fieldsets.find(
        (fieldset) => fieldset.name === "specialOffer",
    );
    const complaintFields = fieldsets.find(
        (fieldset) => fieldset.name === "product",
    );
    const generalFields = fieldsets.find(
        (fieldset) => fieldset.name === "general",
    );

    const [isLoading, setIsLoading] = React.useState(false);
    const [showPersonalDetails, setShowPersonalDetails] = React.useState(false);
    const [message, setMessage] = React.useState<undefined | MessageType>(
        undefined,
    );

    const [personalDetailsFields, setPersonalDetailsFields] = React.useState(
        fieldsets.find((fieldset) => fieldset.name === "Gegevens"),
    );
    const [offerComplaintForm, setOfferComplaintForm] = React.useState<any>(
        createInitialState(offerComplaintFields),
    );
    const [complaintForm, setComplaintForm] = React.useState<any>(
        createInitialState(complaintFields),
    );
    const [generalForm, setGeneralForm] = React.useState<any>(
        createInitialState(generalFields),
    );
    const [personalDetailsForm, setPersonalDetailsForm] = React.useState<any>(
        createInitialState(personalDetailsFields),
    );
    const [files, setFiles] = React.useState<any>();

    const [formType, setFormType] = React.useState<ContactType>();
    const [listOfTypes, setListOfTypes] = React.useState<ContactType[]>([]);
    const [selectedBrand, setSelectedBrand] = React.useState<string>();
    const [listOfBrands, setListOfBrands] = React.useState<FormOptions[]>([]);
    const [selectedTaste, setSelectedTaste] = React.useState<string>();
    const [listOfTastes, setListOfTastes] = React.useState<FormOptions[]>([]);
    const [selectedPackaging, setSelectedPackaging] = React.useState<string>();
    const [listOfPackaging, setListOfPackaging] = React.useState<FormOptions[]>(
        [],
    );
    const [products, setProducts] = React.useState<ProductEntity[]>([]);

    const location = useLocation();

    React.useEffect(() => {
        initiate();
    }, []);

    React.useEffect(() => {
        if (formType && formType.name !== "product") {
            const newComplaintForm = {
                ...complaintForm,
                pickupproduct: "no",
            };
            setComplaintForm(newComplaintForm);
            removeExtendedFields();
        }
    }, [formType]);

    React.useEffect(() => {
        updateListOfTastes();
    }, [selectedBrand]);

    React.useEffect(() => {
        updatelistOfPackaging();
    }, [selectedTaste]);

    const initiate = async () => {
        setIsLoading(true);
        const website =
            configContext.portalKey === "core"
                ? "sourcy"
                : configContext.portalKey.split("_").join("");

        await fetchContactTypes(
            configContext.contactApiUrl,
            formPostSubscriptionKey,
        )
            .then((response) => {
                // We need to filter rewards as type, as it's not a valid type for this form
                const typesData = response.data.filter(
                    (type: ContactTypeEntity) => {
                        if (type.name === "Rewards") return false;
                        return true;
                    },
                );
                const types = typesData.map((type: ContactTypeEntity) => {
                    return new ContactType(type);
                });
                setListOfTypes(types);
                setFormType(types[0]);
            })
            .catch(() => {
                setIsLoading(false);
                setMessage("fail");
            });
        await fetchContactProducts(
            configContext.contactApiUrl,
            formPostSubscriptionKey,
            website,
        )
            .then((allProducts) => {
                const filteredBrands = removeDuplicatesFromNestedArr(
                    allProducts.data,
                    "brand",
                );
                const brandOptions = convertProductListToOptions(
                    filteredBrands,
                    "brand",
                );

                setProducts(allProducts.data);
                setListOfBrands(brandOptions);
                setSelectedBrand(brandOptions[0].value);
            })
            .catch(() => {
                setIsLoading(false);
                setMessage("fail");
            });

        setIsLoading(false);
    };

    const updateListOfTastes = () => {
        if (selectedBrand) {
            const productsFilteredByBrand = products.filter(
                (product) => product.brand === selectedBrand,
            );
            const filteredTastes = removeDuplicatesFromNestedArr(
                productsFilteredByBrand,
                "taste",
            );
            const listOfTastes = convertProductListToOptions(
                filteredTastes,
                "taste",
            );
            setListOfTastes(listOfTastes);
            setSelectedTaste(listOfTastes[0].value);
        }
    };

    const updatelistOfPackaging = () => {
        if (selectedBrand && selectedTaste) {
            const productsFilteredByBrand = products.filter(
                (product) => product.brand === selectedBrand,
            );
            const productsFilteredByTaste = productsFilteredByBrand.filter(
                (product) => product.taste === selectedTaste,
            );
            // A beverage can be served in a 28x20cl gls and a 3x6x20cl pak, both containing 20cl, that's why duplicates need to be removed
            const productsWithoutDuplicates = removeDuplicatesFromNestedArr(
                productsFilteredByTaste,
                "packagingsize",
            );
            const listOfPackaging = convertProductListToOptions(
                productsWithoutDuplicates,
                "packagingsize",
            );

            setListOfPackaging(listOfPackaging);
            setSelectedPackaging(listOfPackaging[0].value);
        }
    };

    const addExtendedFields = () => {
        const extendedFields = fieldsets.find(
            (fieldset) => fieldset.name === "Gegevens lang",
        );
        const extendedFieldsForm = createInitialState(extendedFields);

        if (personalDetailsFields && extendedFields) {
            setPersonalDetailsFields({
                ...personalDetailsFields,
                fields: personalDetailsFields.fields.concat(
                    extendedFields.fields,
                ),
            });
        }

        setPersonalDetailsForm({
            ...personalDetailsForm,
            ...extendedFieldsForm,
        });
    };

    const removeExtendedFields = () => {
        const mutableDetailsForm = { ...personalDetailsForm };
        const extendedFields = fieldsets.find(
            (fieldset) => fieldset.name === "Gegevens lang",
        );

        if (extendedFields) {
            const extendedFieldNames = extendedFields.fields.map(
                (field) => field.name,
            );
            setPersonalDetailsFields(
                fieldsets.find((fieldset) => fieldset.name === "Gegevens"),
            );

            extendedFieldNames.forEach(
                (fieldName) => delete mutableDetailsForm[fieldName],
            );
        }

        setPersonalDetailsForm(mutableDetailsForm);
    };

    const formatData = () => {
        if (!formType) return {};

        const data: FormBackEndModel = {
            ContactType: {
                id: formType.id,
                name: formType.value,
                label: formType.label,
            },
            Contact: {
                FirstName: personalDetailsForm.firstname,
                LastName: personalDetailsForm.lastname,
                Gendercode: personalDetailsForm.gender,
                Email: personalDetailsForm.email,
                PhoneNumber: personalDetailsForm.phone,
                Annotation: "",
                // Check for development when portalKey is set to core
                SourceWebsite: `${
                    configContext.portalKey === "core"
                        ? "sourcy"
                        : configContext.portalKey.split("_").join("")
                }.nl`,
                SourceUrl: location.pathname + location.search,
            },
        };

        if (formType.name === "specialOffer") {
            data.Contact.Annotation = offerComplaintForm.description;
            data.additionalPromotion = {
                Description: offerComplaintForm.specialoffer,
            };
        }

        if (formType.name === "product") {
            const selectedProduct = products.filter(
                (product) =>
                    product.brand === selectedBrand &&
                    product.taste === selectedTaste &&
                    product.packagingsize === selectedPackaging,
            )[0];

            data.Contact.Annotation = complaintForm.description;
            data.additionalComplain = {
                ProductCode: selectedProduct.gpcode,
                ProductName: selectedProduct.name,
                ProductionCode: complaintForm.productioncode,
                ExpirationDate: complaintForm.expirationdate,
                SoldBy: complaintForm.purchasespot,
                PickUpForFree: complaintForm.pickupproduct,
            };

            if (complaintForm.pickupproduct === "yes") {
                data.additionalContactAdress = {
                    Street: personalDetailsForm.street,
                    HouseNumber1: personalDetailsForm.housenumber,
                    HouseNumber2: personalDetailsForm.housenumberaddition || "",
                    City: personalDetailsForm.city,
                    PostalCode: personalDetailsForm.postalcode,
                    Country: personalDetailsForm.country,
                };
            }
        }

        if (formType.name === "general") {
            data.Contact.Annotation = generalForm.description;
        }

        return data;
    };

    const handleInputChange = (
        e: any,
        name: string,
        form: any,
        setForm: (values: any) => void,
        files = null,
    ) => {
        const { value } = e.target;
        const newInputs = {
            ...form,
            [name]: value,
        };
        setForm(newInputs);

        if (name === "pickupproduct" || name === "description") {
            setShowPersonalDetails(true);
        }
        if (name === "pickupproduct") {
            if (value === "yes") return addExtendedFields();
            removeExtendedFields();
        }
        if (files) {
            setFiles(files);
        }
    };

    const changeFormType = (
        e: React.ChangeEvent<HTMLSelectElement>,
        name: string,
    ) => {
        const formType =
            listOfTypes && listOfTypes.find((type) => type.value === name);
        setFormType(formType);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setMessage("loading");

        const formattedData = formatData();

        send(
            configContext.contactApiUrl,
            formPostSubscriptionKey,
            formattedData,
        )
            .then((response) => {
                if (files) {
                    const data = {
                        apiUrl: configContext.contactApiUrl,
                        subscriptionKey: formPostSubscriptionKey,
                        files,
                        contactFormId: response,
                        setMessage,
                    };
                    sendUploadedFiles(data);
                } else setMessage("success");
            })
            .catch(() => setMessage("fail"));
    };

    return (
        <ModuleWrapper moduleType="Form">
            <Container>
                <div className={$.container}>
                    {!message && (
                        <>
                            {title && (
                                <Title className={$.title}>{title}</Title>
                            )}
                            {isLoading && <Loader className={$.loader} />}
                            {!isLoading && (
                                <form
                                    onSubmit={handleSubmit}
                                    className={$.form}
                                >
                                    {listOfTypes && (
                                        <FormSelect
                                            type="select"
                                            title="Waarmee kunnen wij jou helpen?"
                                            placeholder="Waarmee kunnen wij jou helpen?"
                                            required
                                            name="formType"
                                            value={formType && formType.value}
                                            handleChange={changeFormType}
                                            options={listOfTypes}
                                        />
                                    )}
                                    {offerComplaintFields &&
                                        formType &&
                                        formType.name === "specialOffer" && (
                                            <FormFieldset
                                                {...offerComplaintFields}
                                                form={offerComplaintForm}
                                                handleChange={(
                                                    e,
                                                    name,
                                                    files,
                                                ) =>
                                                    handleInputChange(
                                                        e,
                                                        name,
                                                        offerComplaintForm,
                                                        setOfferComplaintForm,
                                                        files,
                                                    )
                                                }
                                            />
                                        )}
                                    {complaintFields &&
                                        formType &&
                                        formType.name === "product" && (
                                            <>
                                                {/* {configContext.portalKey === 'vrumona' && (
                                            <FormSelect
                                                type="select"
                                                title="Om welk product gaat het?"
                                                placeholder="Om welk product gaat het?"
                                                required
                                                name="product"
                                                value={selectedBrand}
                                                formName={formType && formType.value}
                                                handleChange={(e, name) => setSelectedBrand(e.target.value)}
                                                options={listOfTypes}
                                            />
                                        )} */}
                                                <FormSelect
                                                    type="select"
                                                    title="Om welke smaak gaat het?"
                                                    placeholder="Om welke smaak gaat het?"
                                                    required
                                                    name="taste"
                                                    value={selectedTaste}
                                                    handleChange={(e, name) =>
                                                        setSelectedTaste(
                                                            e.target.value,
                                                        )
                                                    }
                                                    options={listOfTastes}
                                                />
                                                <FormSelect
                                                    type="select"
                                                    title="Om welke verpakking gaat het?"
                                                    placeholder="Om welke verpakking gaat het?"
                                                    required
                                                    name="packagingsize"
                                                    value={selectedPackaging}
                                                    handleChange={(e, name) =>
                                                        setSelectedPackaging(
                                                            e.target.value,
                                                        )
                                                    }
                                                    options={listOfPackaging}
                                                />
                                                <FormFieldset
                                                    {...complaintFields}
                                                    form={complaintForm}
                                                    handleChange={(
                                                        e,
                                                        name,
                                                        files,
                                                    ) =>
                                                        handleInputChange(
                                                            e,
                                                            name,
                                                            complaintForm,
                                                            setComplaintForm,
                                                            files,
                                                        )
                                                    }
                                                />
                                            </>
                                        )}
                                    {generalFields &&
                                        formType &&
                                        formType.name === "general" && (
                                            <FormFieldset
                                                {...generalFields}
                                                form={generalForm}
                                                handleChange={(
                                                    e,
                                                    name,
                                                    files,
                                                ) =>
                                                    handleInputChange(
                                                        e,
                                                        name,
                                                        generalForm,
                                                        setGeneralForm,
                                                        files,
                                                    )
                                                }
                                            />
                                        )}
                                    {showPersonalDetails &&
                                        personalDetailsFields && (
                                            <>
                                                <FormFieldset
                                                    {...personalDetailsFields}
                                                    form={personalDetailsForm}
                                                    handleChange={(
                                                        e,
                                                        name,
                                                        files,
                                                    ) =>
                                                        handleInputChange(
                                                            e,
                                                            name,
                                                            personalDetailsForm,
                                                            setPersonalDetailsForm,
                                                            files,
                                                        )
                                                    }
                                                />
                                                <label
                                                    htmlFor="terms"
                                                    className={$.terms}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        value="terms-and-conditions"
                                                        id="terms"
                                                        className={$.termsInput}
                                                        required
                                                    />
                                                    <span
                                                        className={
                                                            $.termsCheckbox
                                                        }
                                                    />
                                                    <span
                                                        className={$.termsLabel}
                                                    >
                                                        Ik ga akkoord met de{" "}
                                                        <a
                                                            href="/pcs"
                                                            className={$.link}
                                                        >
                                                            privacy voorwaarden
                                                        </a>{" "}
                                                        van{" "}
                                                        <span
                                                            className={
                                                                $.brandName
                                                            }
                                                        >
                                                            {configContext.portalKey ===
                                                            "core"
                                                                ? "Sourcy"
                                                                : configContext.portalKey
                                                                      .split(
                                                                          "_",
                                                                      )
                                                                      .join(
                                                                          " ",
                                                                      )}
                                                        </span>
                                                        .
                                                    </span>
                                                </label>
                                            </>
                                        )}
                                    <Button
                                        className={$.sendButton}
                                        text="verstuur"
                                        type="submit"
                                        navy
                                    />
                                </form>
                            )}
                        </>
                    )}
                    {message === "fail" && (
                        <FormResponse
                            title="Er is iets foutgegaan"
                            text="Probeer het later nog eens."
                            onClickFunction={() => setMessage(undefined)}
                            buttonText="Probeer opnieuw"
                        />
                    )}
                    {message === "loading" && (
                        <div>
                            <Title className={$.titleSend}>
                                Aan het verzenden
                            </Title>
                            <Loader className={$.loader} />
                        </div>
                    )}
                    {message === "success" && (
                        <FormResponse
                            title="Verzonden!"
                            text="Bedankt dat je contact met ons hebt opgenomen! We gaan voor je aan de slag en zullen zo spoedig mogelijk reageren."
                            buttonText="Terug naar home"
                            link="/"
                        />
                    )}
                </div>
            </Container>
        </ModuleWrapper>
    );
};

export default Form;
