import * as React from "react";

export type ConfigContextType = {
    apiUrl: string;
    contactApiUrl: string;
    portalKey: "core" | "sourcy" | "royal_club";
};

const ConfigContext = React.createContext<ConfigContextType>({
    apiUrl: "",
    contactApiUrl: "",
    portalKey: "core",
});

export default ConfigContext;
